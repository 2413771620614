// --------- HOME

@import "../modules/_colors.scss";
@import "../modules/_mixins.scss";
@import "../modules/_functions.scss";
@import '../modules/swiper.scss';

#home {
    .home-iperia {

        position: relative;
        z-index: 10;
        min-height: calc(100vh - var(--side-menu-height));
        background-image: url('../../images/page/home/iperia_bg.webp');
        background-position: center;
        background-size: cover;
        padding-top: 70px;
        display: flex;
        flex-direction: column;

        &__container {
            margin: auto;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            & > * {
                z-index: 3;
            }
        }

        video {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 1;
        }

        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url('../../images/page/home/iperia_bg_overlay.webp');
            opacity: 0.5;
            pointer-events: none;
            z-index: 2;
        }

        &__logo {
            margin-bottom: 30px;
            width: 200px;
        }

        &__title {
            width: 100%;
            margin-bottom: 50px;
            text-align: center;

            .title__main,
            .title__sub {
                display: inline-block;
                text-transform: uppercase;
                color: #FFFFFF;
                line-height: 1;
                @include shadow('text-shadow');
                width: 100%;
                max-width: 380px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .title__main {
                font-size: 25px;
                font-weight: 900;
                margin-bottom: 10px;
            }

            .title__sub {
                font-size: 22px;
                font-weight: 100;
            }
        }

        .dots-separator {
            z-index: 3;
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            padding-top: 0;
            &__container {
                padding: 10px 0 0 0;
            }
            &__logo {
                width: 16rem;
                margin-bottom: 2.4rem;
            }
            &__title {

                margin-bottom: 1.7rem;

                .title__main,
                .title__sub {
                    max-width: unset;
                }

                .title__main {
                    font-size: 3.3rem;
                    line-height: 1.05em;
                    margin-bottom: 0.2rem;
                }
                .title__sub {
                    font-size: 2rem;
                    line-height: 1.1em;
                }
            }
        }
    }

    .activity {
        background-color: #FFFFFF;
        &__sector {
            margin-top: 70px;
            margin-bottom: 100px;
            padding: 85px 0 80px;
            background-image: url('../../images/page/home/sector_bg.webp');
            background-size: cover;
            background-position: center;

            .sector__title {
                text-align: center;
            }
        }

        &__stats {
            .stats__item {
                text-align: center;
                padding: 0 10px;
                margin-bottom: 95px;

                &:last-child {
                    margin-bottom: 45px;
                }

                &::before {
                    content: "";
                    display: inline-block;
                    background-position: center;
                    background-repeat: no-repeat;
                    @include filter('purple-dark');
                }

                .stats__highlight {
                    display: block;
                    margin-bottom: 5px;
                    color: $purple-dark-color;
                }

                &--employees,
                &--employers {
                    @extend .stats__item;
                    &::before {
                        height: 78px;
                        margin-bottom: 22px;
                    }
                }

                &--employers::before {
                    width: 164px;
                    background-image: url('../../images/picto/persons_five.svg');
                }

                &--employees::before {
                    width: 100px;
                    background-image: url('../../images/picto/persons_three.svg');
                }

                &--story {
                    @extend .stats__item;
                    &::before {
                        width: 56px;
                        height: 65px;
                        background-image: url('../../images/picto/pappus_1.svg');
                        margin-bottom: 35px;
                    }
                }

                &--path {
                    @extend .stats__item;
                    &::before {
                        width: 90px;
                        height: 110px;
                        background-image: url('../../images/picto/path.svg');
                        @include filter('sub');
                        margin-bottom: 12px;
                    }

                    .stats__highlight {
                        color: $sub-color;
                    }
                }

                &--certification {
                    @extend .stats__item;

                    &::before {
                        width: 81px;
                        height: 81px;
                        background-image: url('../../images/picto/certification.svg');
                        @include filter('sub');
                        margin-bottom: 23px;
                    }

                    .stats__highlight {
                        color: $sub-color;
                    }
                }
            }
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            &__sector {
                padding: 5.7rem 0;
                margin-bottom: 6rem;
                margin-top: 40px;
            }

            &__stats {
                
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 45px;

                .stats__item {
                    flex-basis: 50%;
                    margin-bottom: 6rem;

                    &:last-child {
                        margin-bottom: inherit;
                    }

                    .stats__highlight {
                        margin-bottom: 0.3rem;
                    }

                    &--employees,
                    &--employers {
                        margin-bottom: 5.6rem;

                        &::before {
                            margin-bottom: 20px;
                            width: 230px;
                            height: 100px;
                        }
                    }
    
                    &--story {
                        flex-basis: 100%;
                        margin-bottom: 7rem;
    
                        &::before {
                            width: 60px;
                            height: 55px;
                            margin-bottom: 25px;
                        }
                    }
    
                    &--path::before,
                    &--certification::before {
                        width: 160px;
                        height: 160px;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    .other-news {
        margin-bottom: 130px;
    }

    .understand {
        background-color: #FFFFFF;

        &__container {
            padding-right: 20px;
            padding-left: 20px;
        }

        &__title {
            margin-top: 40px;
            margin-bottom: 35px;
        }

        &__description {
            margin-bottom: 95px;
            @include font-size(20);
            text-align: center;
        }

        .swiper-wrapper {
            display: block;

            // Slide is duplicated to allow looping (desktop only)
            .swiper-slide:nth-child(n + 4) {
                display: none;
            }
        }

        &__carousel {
            margin-bottom: 45px;

            .understand__item {
                position: relative;
                display: inline-block;
                background-size: cover;
                background-position: center;
                width: 100%;
                aspect-ratio: 1;
                margin-bottom: 18px;
                @include link-reset;
                @include hover-animation;

                &:last-child {
                    margin-bottom: 0;
                }

                .item__title {
                    position: absolute;
                    bottom: 25px;
                    left: 0;
                    @include font-size(35);
                    color: #FFFFFF;
                    font-weight: 900;
                    text-transform: uppercase;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include shadow('text-shadow');
                    padding: 0 25px;
                }
            }
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            &__container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 130px 0 135px 6vw;
            }
            &__title,
            &__description {
                text-align: left;
            }
            &__description {
                font-size: 1.25rem;
                max-width: 355px;
            }
            &__carousel {
                position: relative;
                width: 1000px;
                max-width: calc(100% - 410px);
                margin-bottom: 65px;
    
                .understand__item {
                    aspect-ratio: 0.65;
                    aspect-ratio: unset;
                    height: 470px;

                    .item__title {
                        font-size: 1.5rem;
                        line-height: 1.3em;
                        padding: 0 15px;
                    }
                }
            }
            .swiper-wrapper {
                display: flex;
                // Slide is duplicated to allow looping (desktop only)
                .swiper-slide:nth-child(n + 4) {
                    display: initial;
                }
            }
            .swiper-button-next {
                right: 75px;
            }
        }

        @media (min-width: map-get($breakpoints, 'xl')) {
            &__carousel {
                max-width: calc(100% - 530px);
                .understand__item {
                    .item__title {
                        font-size: 1.5rem;
                        padding: 0 20px;
                    }
                }
            }
        }

        @media (min-width: map-get($breakpoints, 'xxl')) {
            &__carousel {
                .understand__item {
                    height: 580px;
                    .item__title {
                        font-size: 1.65rem;
                        padding: 0 25px;
                    }
                }
            }
        }
    }

    .home-items {
        &__item {
            position: relative;
            display: inline-block;
            overflow: hidden;
            width: 100%;
            aspect-ratio: 1;
            margin-bottom: 15px;
            background-size: cover;
            background-position: center;
            @include link-reset;
            @include hover-animation;

            &:last-child {
                margin-bottom: 0;
            }

            .item__title {
                position: absolute;
                left: 0px;
                bottom: 35px;
                @include font-size(35);
                color: #FFFFFF;
                font-weight: 900;
                text-transform: uppercase;
                max-width: 100%;
                overflow: hidden;
                @include shadow('text-shadow');
                word-break: break-word;
                hyphens: auto;
                padding: 0 20px;
            }
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            display: flex;
            justify-content: center;
            gap: 20px;

            &__item {
                height: 580px;
                margin-bottom: 0;
                flex: 0 1 33.333%;

                .item__title {
                    bottom: 40px;
                    font-size: 1.65rem;
                    padding: 0 35px;
                }
            }
        }
    }

    .guarantee {
        background-color: $main-color;
        background-image: url('../../images/geometric_bg.webp');
        background-size: cover;
        color: #FFFFFF;
        text-align: center;

        &__title {
            margin-top: 45px;
            margin-bottom: 40px;
        }

        &__description {
            padding: 0 10px;
            margin: 0 auto 3.6rem auto;
        }

        &__items {
            margin-bottom: 40px;
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            &__title {
                margin-top: 2rem;
                margin-bottom: 3.4rem;
            }

            &__description {
                font-size: 1.25rem;
                max-width: 800px;
            }

            &__items {
                margin-bottom: 2rem;
            }
        }
    }

    .act {
        &__title {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        &__content {
            padding: 150px 10px;
            background-image: url('../../images/peoples_bg.webp');
            background-size: cover;
            background-position: center;
            background-attachment: fixed;
            @include shadow('box-shadow');
            margin-bottom: 100px;

            .act__subtitle {
                text-align: center;
                max-width: 1000px;
                margin: auto;
                word-break: break-word;
                hyphens: auto;

                &::after {
                    content: "";
                    display: block;
                    height: 40px;
                    @include background-picto('a_bottom.svg');
                    @include filter('white');
                    margin-top: 45px;
                }
            }
        }

        &__items {
            margin-bottom: 50px;
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            &__title {
                margin-top: 2rem;
                margin-bottom: 4rem;
            }

            &__content {
                padding: 4.5rem 0 4rem 0;
                margin-bottom: 8rem;
            }

            &__items {
                margin-bottom: 6rem;
            }
        }
    }

    .collaborate {
        background-image: url('../../images/page/home/collaborate_bg.webp');
        background-size: cover;
        background-position: center;
        color: #FFFFFF;
        text-align: center;
        padding-bottom: 145px;

        &__title {
            margin-top: 45px;
            margin-bottom: 40px;
        }

        &__description {
            @include font-size(20);
            padding: 0 36px;
            margin: 0 auto 95px;
        }

        @media (min-width: map-get($breakpoints, 'lg')) {
            padding-bottom: 165px;

            &__title {
                margin-top: 1.7rem;
                margin-bottom: 3.5rem;
            }

            &__description {
                font-size: 1.25rem;
                padding: 0;
                max-width: 630px;
                margin-bottom: 3rem;
            }
        }
    }
}
// --------- COLORS

$background-color: #F8F6FB;
$white: #FFFFFF;
$purple-dark-color: #521E87;
$purple-light-color: #9853E1;

$main-color: #26123D;
$sub-color: #FF8E00;

$colors: (
    'main': $main-color,
    'sub': $sub-color,
    'white': $white,
    'purple-dark': $purple-dark-color,
    'purple-light': $purple-light-color
);

$colors-filter: (
    'main': invert(6%) sepia(31%) saturate(5420%) hue-rotate(256deg) brightness(101%) contrast(96%),
    'sub': invert(51%) sepia(92%) saturate(1357%) hue-rotate(0deg) brightness(103%) contrast(105%),
    'white': invert(100%) sepia(0%) saturate(7500%) hue-rotate(358deg) brightness(110%) contrast(105%),
    'purple-dark': invert(13%) sepia(71%) saturate(3093%) hue-rotate(261deg) brightness(93%) contrast(98%),
    'purple-light': invert(43%) sepia(19%) saturate(3672%) hue-rotate(238deg) brightness(90%) contrast(96%)
);
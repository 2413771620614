// --------- SWIPER

@import '~swiper/css';
@import '~swiper/css/pagination';


// Pagination
:root {
    --swiper-pagination-color: #FFFFFF;
    --swiper-pagination-bullet-inactive-color: transparent;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-size: 10px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
    --swiper-pagination-bottom: 30px;
}
.swiper-pagination {
    display: none;
}

.swiper-pagination-bullet {
    border: solid 1px #9e1d1d;
}

// Navigation
.swiper-button-prev,
.swiper-button-next {
    display: none;
}

@media (min-width: map-get($breakpoints, 'lg')) {
    // Pagination
    .swiper-pagination {
        display: block;
    }

    // Navigation
    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        width: 55px;
        height: 55px;
        cursor: pointer;
        position: absolute;
        top: calc(50% - 31px);
        z-index: 5;
        @include background-picto();
    }
    .swiper-button-prev {
        left: -30px;
        background-image: url('../../images/picto/a_left_full.svg');

        &.no-sticky {
            left: -65px;
        }
    }
    .swiper-button-next {
        right: -30px;
        background-image: url('../../images/picto/a_right_full.svg');

        &.no-sticky {
            right: -65px;
        }
    }
}
@media (min-width: 1480px) {
    // Navigation
    .swiper-button-prev,
    .swiper-button-next {
        width: 70px;
        height: 70px;
    }
    .swiper-button-prev {
        &.no-sticky {
            left: -140px;
        }
    }
    .swiper-button-next {
        &.no-sticky {
            right: -140px;
        }
    }
}

// @media (min-width: map-get($breakpoints, 'xxxl')) {
//     .swiper-button-prev,
//     .swiper-button-next {
//         top: calc(50% - 41px);
//     }
//     .swiper-button-prev {

//         &.no-sticky {
//             left: calc(-24vw + 270px);
//         }
//     }
//     .swiper-button-next {

//         &.no-sticky {
//             right: calc(-24vw + 270px);
//         }
//     }
// }
// --------- MIXINS

@import '../_vars.scss';
@import '_colors.scss';

@mixin vertical-center {
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

@mixin filter($color, $important: false) {
    @if ($important == true) {
        filter: map-get($colors-filter, $color) !important;
    }
    @else {
        filter: map-get($colors-filter, $color);
    }
}

@mixin font-size($size) {
    font-size: #{$size}px;
    line-height: #{map-get($font-sizes, $size)}px;
}

@mixin background-picto ($picto: '') {
    @if $picto != '' {
        background-image: url('../../images/picto/#{$picto}');
    }
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

@mixin link-reset {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
}

@mixin list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin shadow($property: 'box-shadow') {
    #{$property}: 0px 3px 6px #00000029;
}

@mixin hover-animation-initial-state {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: background-color .3s;
    }
    &::after {
        content: "+";
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: serif;
        font-size: 100px;
        font-weight: 100;
        color: transparent;
        transform: rotate(-90deg);
        transition: all .3s;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        &::after {
            font-size: 135px;
        }
    }
}

@mixin hover-animation-active-state {
    &::before {
        background-color: add-opacity($sub-color, 40);
    }
    &::after {
        color: white;
        transform: rotate(0);
    }
}

@mixin hover-animation {
    @include hover-animation-initial-state;

    &:hover {
        @include hover-animation-active-state;
    }
}

@mixin text-bordered {
    padding-left: 25px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 6px;
        background-color: $sub-color;
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
        padding-left: 2rem;
    }
}

@mixin paragraph {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
    @media (min-width: map-get($breakpoints, 'lg')) {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }
}

@mixin content-text-size {

    // font-size: 1rem;

    @media (min-width: map-get($breakpoints, 'lg')) {
        // font-size: 1.2vw;
        // line-height: 1.68vw;
    }

    @media (min-width: map-get($breakpoints, 'xxl')) {
        // font-size: 1.302085vw;
        // line-height: 1.822919vw;
    }
}

@mixin rounded-border($color: $main-color, $overflow: hidden) {
    border: solid 2px $color;
    border-radius: 20px;
    overflow: $overflow;
}


@mixin apply-color($color, $property, $important: false) {
    @if ($important == true) {
        #{$property}: map-get($colors, $color) !important;
    }
    @else {
        #{$property}: map-get($colors, $color);
    }
}

@mixin category-badge() {
    display: inline-block;
    padding: 12px 26px;
    border: 2px solid #000000;
    border-radius: 100px;
    color: #000000;
    margin-bottom: 18px;
    // @media (min-width: map-get($breakpoints, 'xxxl')) {
    //     padding: 14px 35px 15px;
    // }
}
